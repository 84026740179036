import Glide from "@glidejs/glide"

const hero = document.querySelector(".hero")
const project = document.querySelector(".project")
const logos = document.querySelector(".logos")
const carousel = document.querySelector(".carousel")

if (hero) {
    new Glide(".hero", {
        type: "slider",
        autoplay: 5000,
    }).mount()
}

if (project) {
    new Glide(".project", {
        type: "carousel",
    }).mount()
}

if (logos) {
    new Glide(".logos", {
        type: "carousel",
        startAt: 0,
        perView: 5,
        gap: 0,
        swipeThreshold: false,
        dragThreshold: false,
        autoplay: 1,
        animationDuration: 3000,
        animationTimingFunc: "linear",
        peek: { before: 70, after: 70 },
        breakpoints: {
            600: {
                perView: 3,
                gap: 30,
                animationDuration: 1500,
            },
            800: {
                perView: 4,
                animationDuration: 2000,
            },
        },
    }).mount()
}

if (carousel) {
    new Glide(".carousel", {
        type: "carousel",
        startAt: 0,
        perView: 4,
        gap: 20,
        peek: { before: 70, after: 70 },
        breakpoints: {
            560: {
                perView: 1,
            },
            800: {
                perView: 2,
            },
            1100: {
                perView: 3,
            },
        },
    }).mount()
}

const callback = (entries) => {
    entries.forEach(({ target, isIntersecting }) => {
        if (isIntersecting) {
            target.classList.add("reveal__visible")
        }
    })
}

const observer = new IntersectionObserver(callback)

document.querySelectorAll(".reveal").forEach((el) => observer.observe(el))

const hb = document.querySelector(".hamburger")
hb.addEventListener("click", () => {
    document.body.classList.toggle("nav-open")
})
